var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.egress_profile_matter)?_c('AchievementRangeGroupSelector',{attrs:{"egress_profile_id":_vm.egress_profile_matter.egress_profile},on:{"selected_range_group":_vm.selectedRangeGroup}}):_vm._e(),_c('b-tabs',{attrs:{"content-class":"mt-3"},model:{value:(_vm.tab_index),callback:function ($$v) {_vm.tab_index=$$v},expression:"tab_index"}},[(_vm.institution && _vm.institution.internal_use_id != 'duoc_uc')?_c('b-tab',{attrs:{"title":`${_vm.$getVisibleNames(
        'mesh.egressprofilematter',
        false,
        'Asignatura'
      )}`}},[(
          _vm.egress_profile_matter &&
          _vm.show_assessment_range_table &&
          _vm.egress_profile_matter.assessment
        )?_c('AssessmentRangeTable',{key:_vm.selected_achievement_range_group,attrs:{"section_ids":_vm.sections_ids,"main_assessment":_vm.egress_profile_matter.assessment,"achievement_range_group":_vm.selected_achievement_range_group,"grade":true,"campuses_":_vm.campuses_,"egress_profiles_":_vm.egress_profiles_,"levels_":_vm.levels_,"matters_":_vm.matters_,"periods_":_vm.periods_,"sections_":_vm.sections_,"title_graphic":`REPORTE DE ASSESSMENT POR ${_vm.$getVisibleNames(
          'mesh.egressprofilematter',
          false,
          'Asignatura'
        ).toUpperCase()}`}}):_vm._e()],1):(
        _vm.institution && _vm.institution.internal_use_id == 'duoc_uc' && _vm.matter
      )?_c('b-tab',{attrs:{"title":`${_vm.$getVisibleNames(
        'mesh.egressprofilematter',
        false,
        'Asignatura'
      )}`}},[(_vm.show_assessment_range_table && _vm.matter.assessment)?_c('AssessmentRangeTable',{key:_vm.selected_achievement_range_group,attrs:{"section_ids":_vm.sections_ids,"main_assessment":_vm.matter.assessment,"achievement_range_group":_vm.selected_achievement_range_group,"grade":true,"campuses_":_vm.campuses_,"egress_profiles_":_vm.egress_profiles_,"levels_":_vm.levels_,"matters_":_vm.matters_,"periods_":_vm.periods_,"sections_":_vm.sections_,"title_graphic":``}}):_vm._e()],1):_vm._e(),(
        _vm.institution &&
        (_vm.institution.internal_use_id == 'ciisa_uss' ||
          _vm.institution.show_competence_unit)
      )?_c('b-tab',{attrs:{"title":_vm.$getVisibleNames(
          'teaching.tempcompetenceunit',
          false,
          'Unidad de competencia'
        )}},[_c('AssessmentRangeSelector',{attrs:{"manual_assessment_type_id":4,"egress_profile_matter":_vm.egress_profile_matter},on:{"change_assessment":_vm.changeAssessment4}}),(_vm.assessment_id_4 != null && _vm.show_assessment_range_table)?_c('AssessmentRangeTable',{key:`${_vm.key_assessment_range_table_4}-${_vm.selected_achievement_range_group}`,attrs:{"section_ids":_vm.sections_ids,"main_assessment":_vm.assessment_id_4,"achievement_range_group":_vm.selected_achievement_range_group,"campuses_":_vm.campuses_,"egress_profiles_":_vm.egress_profiles_,"levels_":_vm.levels_,"matters_":_vm.matters_,"periods_":_vm.periods_,"sections_":_vm.sections_,"title_graphic":`REPORTE DE ASSESSMENT POR ${_vm.$getVisibleNames(
          'teaching.tempcompetenceunit',
          false,
          'Unidad de competencia'
        ).toUpperCase()}`}}):_vm._e()],1):_vm._e(),_c('b-tab',{attrs:{"title":_vm.$getVisibleNames('teaching.ramicro', false, 'RA MICRO')}},[_c('AssessmentRangeSelector',{attrs:{"manual_assessment_type_id":2,"egress_profile_matter":_vm.egress_profile_matter},on:{"change_assessment":_vm.changeAssessment2}}),(_vm.assessment_id_2 != null && _vm.show_assessment_range_table)?_c('AssessmentRangeTable',{key:`${_vm.key_assessment_range_table_2}-${_vm.selected_achievement_range_group}`,attrs:{"section_ids":_vm.sections_ids,"main_assessment":_vm.assessment_id_2,"achievement_range_group":_vm.selected_achievement_range_group,"campuses_":_vm.campuses_,"egress_profiles_":_vm.egress_profiles_,"levels_":_vm.levels_,"matters_":_vm.matters_,"periods_":_vm.periods_,"sections_":_vm.sections_,"title_graphic":`REPORTE DE ASSESSMENT POR ${_vm.$getVisibleNames(
          'teaching.ramicro',
          false,
          'RA MICRO'
        ).toUpperCase()}`}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }